.image-flair-background{
    background:rgb(46, 56, 89);
    margin:0px;
    color:#ffffff;
    height:100vmin;
    display:grid;
    place-items:center;
}

.middle{
display:grid;
place-items:center;
text-align: left;
padding:3vmin;}

.card-groups,
.card-group,
 card{
    aspect-ratio: 5 / 7;
}

.card-groups,
.card-group,
 .big-card{
    width: 30vmin;
}

.card-group {
    position:absolute;
    transition:transform 400ms ease;
}


.card{    
    aspect-ratio: 5 / 7;
    background-color: rgba(0, 0, 0, 0.1);
    position: absolute;
    transition: transform 800ms cubic-bezier(.05,.43,.25,.95);
}

.big-card{
    width: 30vmin;
    border-radius: 5vmin;

}

.small-card{
    width: 12vmin;
    border-radius: 2vmin;
    left:50%;
    top:50%;
    transform: translate(-50%, -50%);

}



  
.card-group:hover > .big-card:nth-child(2){
    transform: translate(-75%, 16%) rotate(-24deg);
}
.card-group:hover > .big-card:nth-child(4){
    transform: translate(-25%, 8%) rotate(-8deg);
}
.card-group:hover > .big-card:nth-child(6){
    transform: translate(25%, 8%) rotate(8deg);
}
.card-group:hover > .big-card:nth-child(8){
    transform: translate(75%, 16%) rotate(24deg);
}





